import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1%",
    background:
      "linear-gradient(to right, rgba(70, 87, 98, 0.9), rgba(40, 51, 61, 0.9))",

    // "&:hover": {
    //   background:
    //     "linear-gradient(to right, rgba(72, 85, 99, 0.5), rgba(41, 50, 60, 0.5))",
    // },
  },
  containerSelf: {
    marginTop: "1%",
    background:
      "linear-gradient(to right, rgba(72, 85, 99, 0.5), rgba(41, 50, 60, 0.5))",
    // "&:hover": {
    //   background:
    //     "linear-gradient(to right, rgba(70, 87, 98, 0.9), rgba(40, 51, 61, 0.9))",
    // },
  },
  header: {
    textAlign: "left",
  },
  text: {
    textAlign: "left",
    marginLeft: theme.spacing(5),
    color: "white",
  },
  media: {
    marginTop: theme.spacing(2),
  },
  post_img: {
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(80),
    },
    width: theme.spacing(40),
    objectFit: "contain",
  },
  avatar: {},
}));

const ChatRow = ({ names, chatId, lastMsg }: any) => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.container}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="user-pic"
              className={classes.avatar}
              alt={names}
              src={names}
            />
          }
          title={names}
          subheader={lastMsg}
          className={classes.header}
        />
      </Card>
    </div>
  );
};

export default ChatRow;
