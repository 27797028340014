import { IDS } from "../config/constants";
import { db, auth } from "../config/fire";
import { getDateFromStamp } from "./util";

export const GET_USER = (uid: string) => {
  // let id = appConfig.firebase.auth().currentUser?.uid;
  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(uid)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //   let row = {};
          var itemVal = doc.data();
          resolve(itemVal);
        } else {
          resolve({});
          console.log("User data not found");
          // reject("User data not found");
        }
      })
      .catch((error) => {
        reject("Error getting user data: " + error);
      });
  });
};

export const GET_PROPERTY_AGENT = (uid?: string) => {
  return new Promise((resolve, reject) => {
    if (!uid) reject("Agent ID Error");
    db.collection("properties")
      .where("userId", "==", uid)
      .get()
      .then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          reject("No data");
        }

        let propertyItem: any[] = [];

        querySnapshot.forEach((doc: any) => {
          var itemVal = doc.data();

          itemVal.id = doc.id;
          itemVal.created_at = getDateFromStamp(itemVal.created_at);

          propertyItem.push(itemVal);
        });

        propertyItem.sort((a, b) =>
          b.created_at > a.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
        );

        resolve(propertyItem);
      })
      .catch((error) => {
        reject("Error getting datas: " + error);
      });
  });
};

export const GET_PROPERTY = () => {
  return new Promise((resolve, reject) => {
    db.collection("properties")
      .get()
      .then(async function (querySnapshot) {
        if (querySnapshot.empty) {
          reject("No data");
        }

        let propertyItem: any[] = [];

        querySnapshot.forEach((doc: any) => {
          var itemVal = doc.data();

          itemVal.id = doc.id;
          itemVal.created_at = getDateFromStamp(itemVal.created_at);

          propertyItem.push(itemVal);
        });

        propertyItem.sort((a, b) =>
          b.created_at > a.created_at ? 1 : a.created_at > b.created_at ? -1 : 0
        );

        resolve(propertyItem);
      })
      .catch((error) => {
        reject("Error getting datas: " + error);
      });
  });
};

export const GET_PROPERTY_BY_ID = (postId: string) => {
  return new Promise((resolve, reject) => {
    db.collection("properties")
      .doc(postId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let itemVal = doc.data();
          resolve(itemVal);
        } else {
          resolve({});
          console.log("User data not found");
        }
      })
      .catch((error) => {
        reject("Error getting user data: " + error);
      });
  });
};

export const GET_FAV_PROPERTY = (uid?: string) => {
  return new Promise((resolve, reject) => {
    if (!uid) {
      reject("UID Error");
      return;
    }

    db.collection(IDS.USER_ACTIONS)
      .doc(IDS.FAVOURITES)
      .collection(uid)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          reject("No Favourite found");
          return;
        }
        let favList: any = [];

        querySnapshot.forEach((doc: any) => {
          var itemVal = doc.data();

          if (itemVal[doc.id] == true) {
            favList.push({
              postId: doc.id,
            });
          }
        });

        let itemsProcessed = 0;
        let resultsArr: any = [];

        // return;
        favList.forEach(async (value: any, rowID: any, array: any) => {
          let property: any = await GET_PROPERTY_BY_ID(value.postId);

          itemsProcessed++;

          let result = {
            postId: value.postId,
            ...property,
          };

          resultsArr.push(result);

          if (itemsProcessed === array.length) {
            resolve(resultsArr);
          }
        });
      });
  });
};

export const GET_FRIENDS = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    let friendsListArr = [];
    let chatRefA: any = await getChatNodeA(id).catch((err) => {
      console.log(err);
    });
    let chatRefB: any = await getChatNodeB(id).catch((err) => {
      console.log(err);
    });

    if (chatRefA && chatRefA.foundEntry) {
      // console.log(chatRefA.friendsList);
      // console.log("--------");
      friendsListArr = chatRefA.friendsList;
    }

    if (chatRefB && chatRefB.foundEntry) {
      // console.log(chatRefB);
      // console.log("--------");
      friendsListArr = [...friendsListArr, ...chatRefB.friendsList];
    }

    resolve(friendsListArr);
  });
};

const getChatNodeA = (id: string) => {
  return new Promise((resolve, reject) => {
    db.collection("friends")
      .where("userA", "==", id)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          reject("No Friends Found A");
          return;
        }
        // Freinds found init for each and get friend data from ID
        let friends: any = [];

        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());

          var itemVal = doc.data();

          friends.push({
            id: doc.id,
            friendId: itemVal.userB,
          });
        });

        let itemsProcessed = 0;
        let resultsArr: any = [];

        // return;
        friends.forEach(async (value: any, rowID: any, array: any) => {
          let lastChat = await getLastChat(value.id, id);
          let user = await GET_USER(value.friendId.trim());
          // console.log(lastChat, "Chat Update");
          // console.log("Friend Log", value.friendId, user);

          itemsProcessed++;

          let result = {
            id: value.id,
            user,
            lastChat: lastChat ? lastChat : "Start Conversation",
          };

          console.log("ABBBBBB", result);

          resultsArr.push(result);

          if (itemsProcessed === array.length) {
            // console.log("Last message recieved");

            // let filteredData = Object.values(resultsArr).sort(
            //   (a: any, b: any) => a.lastChat.order - b.lastChat.order
            // );
            resolve({
              foundEntry: resultsArr.length > 0 ? true : false,
              friendsList: resultsArr,
            });
          }
        });
      })
      .catch(function (error) {
        reject(error + "Error finding friends 22A");
      });
  });
};

export const getChatNodeB = (id: string) => {
  return new Promise((resolve, reject) => {
    db.collection("friends")
      .where("userB", "==", id)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          reject("No Friends Found B");
          return;
        }
        // Freinds found init for each and get friend data from ID
        let friends: any = [];

        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());

          var itemVal = doc.data();

          friends.push({
            id: doc.id,
            friendId: itemVal.userA,
          });
        });

        let itemsProcessed = 0;
        let resultsArr: any = [];

        // return;
        friends.forEach(async (value: any, rowID: any, array: any) => {
          let user = await GET_USER(value.friendId);
          let lastChat = await getLastChat(value.id, id);
          // console.log(lastChat, "Chat Update");
          console.log("GBBBBBBBB", user);
          itemsProcessed++;

          let result = {
            id: value.id,
            user,
            lastChat: lastChat ? lastChat : "Start Conversation",
          };

          // console.log(result);

          resultsArr.push(result);

          if (itemsProcessed === array.length) {
            // console.log("Last message recieved");

            // let filteredData = Object.values(resultsArr).sort(
            //   (a: any, b: any) => a.lastChat.order - b.lastChat.order
            // );
            resolve({
              isLoadingFriends: false,
              foundEntry: resultsArr.length > 0 ? true : false,
              friendsList: resultsArr,
            });
          }
        });
      })
      .catch(function (error) {
        // console.log("Error getting documents: ", error);
        reject(error + "Error finding friends 22B");
      });
  });
};

const getLastChat = (id: string, myuid: any) => {
  let chatSnap: any = {};
  let notificationCount = 0;
  let itemsProcessed = 0;

  return new Promise((resolve, reject) => {
    db.collection("chat")
      .doc("messages")
      .collection(id)
      .orderBy("createdAt", "desc")
      .limit(10)
      .get()
      .then(function (querySnapshot) {
        itemsProcessed++;

        if (!querySnapshot.empty) {
          let count = 0;
          querySnapshot.forEach(function (doc) {
            var itemVal = doc.data();

            if (count == 0) {
              chatSnap.lastChat = itemVal.text ? itemVal.text : "Image";
              chatSnap.timeStamp = itemVal.createdAt.toDate();
              chatSnap.order = itemVal.order;
            }

            if (!itemVal.read && itemVal.uid != myuid) {
              notificationCount++;
              // chatSnap.push({ notificationCount });
              chatSnap.unreadCount = notificationCount;
            }
            // console.log("Message: " + itemVal.text, notificationCount);
            count++;
          });
        } else {
          chatSnap.lastChat = "Start a chat";
          // resolve(chatSnap);
        }
        resolve(chatSnap);
      });
  });
};

export const algoChatID = (strA: string, strB: string) => {
  return new Promise((resolve, reject) => {
    let arr = [strA, strB];

    const sorted = arr.sort((a, b) => {
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });

    resolve({ id: sorted[0] + sorted[1], arr: sorted });
  });
};
