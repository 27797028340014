import "animate.css"; //npm install animate.css
import { ICity, IState } from "country-state-city/dist/lib/interface";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../components/footer";
import { Navbar } from "../../components/Navbar";
import ScrollButton from "../../components/ScrollButton";
import { Sidebar } from "../../components/Sidebar";
import { db, storage, firebase } from "../../config/fire";
import { pptType } from "../../helper/interface";
import "./addproperty.scss";
import { City, State } from "country-state-city";
import Modal from "react-modal";
import ClearIcon from "@material-ui/icons/Clear";
import ScrollableFeed from "react-scrollable-feed";
import { COUNTRIES } from "../../config/constants";

interface ImgProps {
  path: any;
  url: any;
  name: string;
}

const TYPES = ["Home Rentals", "Apartment Rentals"];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
  },
  h2: {
    fontSize: "80px",
  },
};
let ccState = COUNTRIES;
let ccCities = City.getCitiesOfState("US", "AZ");
export const AddProperty = () => {
  const history = useHistory();
  const [formData, setFormData] = useState<pptType>({
    propertyTitle: "",
    price: "",
    area_sqft: "",
    bedroom: "1",
    bathroom: "1",
    city: "Phoenix",
    state: "Arizona",
    type: TYPES[0],
    address: "",
    postal_code: "",
    building_age: "",
    information: "",
    laundry_room: false,
    free_parking: false,
    balcony: false,
    swimmingpool: false,
    rent: false,
    sale: false,
    userId: "",
    videoUrl: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
  });

  const [imgArr, setImgArr] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [states, setStates] = useState<IState[]>(ccState);
  const [cities, setCities] = useState<ICity[]>(ccCities);

  useEffect(() => {
    // setStates(COUNTRIES);
    // setCities(City.getCitiesOfState("US", "AZ"));
  });

  const {
    propertyTitle,
    price,
    area_sqft,
    bedroom,
    bathroom,
    address,
    city,
    state,
    type,
    postal_code,
    building_age,
    information,
    laundry_room,
    free_parking,
    balcony,
    swimmingpool,
    rent,
    sale,
    contactName,
    contactEmail,
    contactPhone,
    videoUrl,
  } = formData;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalCity, setModalCity] = React.useState(false);
  const [modalType, setModalType] = React.useState(false);

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheck = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleFireBaseUpload = (name: string, file: any) => {
    if (!name) {
      toast("Please select property image");
      // console.error(`not an image, the image file is a ${typeof imageAsFile}`);
      return;
    }

    setUploading(true);

    const uploadTask = storage.ref(`/property/${name}`).put(file);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot: any) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      (err: any) => {
        //catches the errors
        setUploading(false);
        console.log(err);
        toast("Error uploading image");
      },
      () => {
        setUploading(false);
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("property")
          .child(name)
          .getDownloadURL()
          .then((fireBaseUrl: string) => {
            let imgs: string[] = imgArr;
            imgs.push(fireBaseUrl);

            setImgArr(imgs);
          });
      }
    );
  };

  function closeModal() {
    setIsOpen(false);
  }
  function closeModalType() {
    setModalType(false);
  }

  function closeModalCity() {
    setModalCity(false);
  }

  const createProperty = (e: any) => {
    e.preventDefault();

    if (!propertyTitle) {
      toast("Propery title is required");
      return;
    }

    if (!price) {
      toast("Price is required");
      return;
    }

    if (!area_sqft) {
      toast("Enter Area in Square ft");
      return;
    }

    if (!address) {
      toast("Address is required");
      return;
    }

    if (!postal_code) {
      toast("Postal code is required");
      return;
    }
    if (!rent && !sale) {
      toast("Please check if Rent or sale");
      return;
    }

    if (uploading) {
      toast("Please wait for upload to complete");
      return;
    }

    if (_.isEmpty(imgArr)) {
      toast("Please upload atleast one image");
      return;
    }

    setLoading(true);
    let id = firebase.auth().currentUser?.uid;

    let snap = {
      propertyTitle,
      userId: id,
      price,
      area_sqft,
      bedroom,
      bathroom,
      address,
      city,
      state,
      type,
      postal_code,
      building_age,
      information,
      laundry_room,
      free_parking,
      balcony,
      swimmingpool,
      rent,
      sale,
      contactName,
      contactEmail,
      contactPhone,
      created_at: Date.now(),
      images: imgArr,
      videoUrl,
    };

    db.collection("properties")
      .add(snap)
      .then((snapshot) => {
        toast("Property created sucessfully");

        setLoading(false);
        history.goBack();
      });
  };

  return (
    <div id={"addproperty"} className="add-wrapper">
      <ToastContainer />

      <section className="add-header">
        <Navbar />
        <Sidebar />
        <header>
          <div className="w-100">
            <div className="header-text">Add Property</div>
          </div>
        </header>
      </section>

      <div className={"d-flex justify-content-center"}>
        <div
          className={
            "col-lg-12 col-md-112 col-sm-12 search-box d-flex align-items-center row"
          }
        />
      </div>

      <section id={"property-listing"} className={"container"}>
        {/* START */}

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-area contact-2">
                <div className="search-area-inner">
                  <div className="search-contents ">
                    <form>
                      <h3 className="heading">Basic Information</h3>
                      <div className="row mb-50">
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group required">
                            <label>Property Title</label>
                            <input
                              type="text"
                              id="propertyTitle"
                              name="propertyTitle"
                              className="form-control"
                              placeholder="Property Title"
                              value={propertyTitle}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group required">
                            <label>Price</label>
                            <input
                              type="text"
                              id="price"
                              name="price"
                              className="form-control"
                              placeholder="USD"
                              value={price}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group required">
                            <label>Area/Location</label>
                            <input
                              type="text"
                              id="area_sqft"
                              name="area_sqft"
                              className="form-control"
                              placeholder="SqFt"
                              value={area_sqft}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group required">
                            <label>{"Video Url"}</label>
                            <input
                              type="text"
                              id="videoUrl"
                              name="videoUrl"
                              className="form-control"
                              placeholder="http:// youtube url"
                              value={videoUrl}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group ">
                            <label>Bedrooms</label>
                            <select
                              id="bedroom"
                              className="selectpicker search-fields"
                              name="bedroom"
                              value={bedroom}
                              onChange={(e) => onChange(e)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="form-group ">
                            <label>Bathroom</label>
                            <select
                              id="bathroom"
                              className="selectpicker search-fields"
                              name="bathroom"
                              value={bathroom}
                              onChange={(e) => onChange(e)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <h3 className="heading">Location</h3>
                      <div className="row mb-50">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group required">
                            <label>Address</label>
                            <input
                              id="address"
                              type="text"
                              name="address"
                              className="form-control"
                              placeholder="Address"
                              value={address}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group click">
                            <label>State</label>
                            <div
                              className={"click"}
                              onClick={() => {
                                setIsOpen(true);
                              }}
                            >
                              {state}{" "}
                              <span
                                className="iconify"
                                data-icon="dashicons:arrow-down-alt2"
                                data-inline="false"
                              />
                            </div>
                          </div>

                          <div className="form-group click">
                            <label>City</label>
                            <div
                              className={"click"}
                              onClick={() => {
                                setModalCity(true);
                              }}
                            >
                              {city}{" "}
                              <span
                                className="iconify"
                                data-icon="dashicons:arrow-down-alt2"
                                data-inline="false"
                              />
                            </div>
                          </div>
                          <div className="form-group click">
                            <label>Type</label>
                            <div
                              className={"click"}
                              onClick={() => {
                                setModalType(true);
                              }}
                            >
                              {type}{" "}
                              <span
                                className="iconify"
                                data-icon="dashicons:arrow-down-alt2"
                                data-inline="false"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group required">
                            <label>Postal Code</label>
                            <input
                              id="code"
                              type="number"
                              name="postal_code"
                              className="form-control"
                              placeholder="Postal Code"
                              value={postal_code}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group required">
                            <label>Building Age</label>
                            <input
                              id="age"
                              type="number"
                              name="building_age"
                              className="form-control"
                              placeholder="Building Age"
                              value={building_age}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <h3 className="heading">Detailed Information</h3>
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="form-group message">
                            <label>Detailed Information</label>
                            <textarea
                              id="detail"
                              className="form-control"
                              name="information"
                              placeholder="Detailed Information"
                              value={information}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <h3 className="heading">Features (optional)</h3>
                      <div className="row mb-50">
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={"laundry_room"}
                                checked={laundry_room}
                                onChange={(e) => onCheck(e)}
                                id="laundry_room"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="laundry_room"
                              >
                                Laundry Room
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={"free_parking"}
                                checked={free_parking}
                                onChange={(e) => onCheck(e)}
                                id="free_parking"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free_parking"
                              >
                                Free Parking
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={"balcony"}
                                checked={balcony}
                                onChange={(e) => onCheck(e)}
                                id="balcony"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="balcony"
                              >
                                Balcony
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={"swimmingpool"}
                                checked={swimmingpool}
                                onChange={(e) => onCheck(e)}
                                id="swimmingpool"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="swimmingpool"
                              >
                                Swimming Pool
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={"rent"}
                                checked={rent}
                                onChange={(e) => onCheck(e)}
                                id="rent"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rent"
                              >
                                Rent
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-check checkbox-theme">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={sale}
                                name={"sale"}
                                onChange={(e) => onCheck(e)}
                                id="sale"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sale"
                              >
                                Sale
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h3 className="heading">Contact Details</h3>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group required">
                            <label>Name</label>
                            <input
                              id="contactName"
                              type="text"
                              name="contactName"
                              className="form-control"
                              placeholder="Name"
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group required">
                            <label>Email</label>
                            <input
                              id="contactEmail"
                              type="email"
                              name="contactEmail"
                              className="form-control"
                              placeholder="Email"
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group required">
                            <label>Phone</label>
                            <input
                              type="text"
                              id="contactPhone"
                              name="contactPhone"
                              className="form-control"
                              placeholder="Phone"
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>

                        <div className="file-picker">
                          <label
                            className="picker flex-center"
                            htmlFor="fileUpload"
                          >
                            {uploading ? (
                              <div className={"flex-center spinner"}>
                                <ReactLoading
                                  className={"spinner"}
                                  type={"spin"}
                                  color={"#71db77"}
                                  height={"100%"}
                                  width={"70%"}
                                />
                              </div>
                            ) : (
                              <>
                                <span className="mr-2">
                                  <span
                                    className="iconify"
                                    data-icon="et:upload"
                                    data-inline="false"
                                  ></span>
                                </span>
                                Select property image
                              </>
                            )}
                          </label>

                          <input
                            type="file"
                            id="fileUpload"
                            onChange={(e) => {
                              if (uploading) return;

                              if (e.target.files) {
                                setUploading(true);

                                const { files } = e.target;
                                console.log(files[0].name);
                                handleFireBaseUpload(files[0].name, files[0]);
                              }
                            }}
                          />
                        </div>

                        <div className={"col-12"}>
                          {imgArr.map((element) => (
                            <img
                              key={element}
                              className={"img-upload"}
                              src={element}
                            />
                          ))}
                        </div>

                        <div className="col-lg-4 my-4">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => createProperty(e)}
                          >
                            {loading ? (
                              <div className={"flex-center spinner"}>
                                <ReactLoading
                                  className={"spinner"}
                                  type={"spin"}
                                  color={"#fff"}
                                  height={"100%"}
                                  width={"70%"}
                                />
                              </div>
                            ) : (
                              "Submit Property"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* END */}
      </section>

      {/* <section id={"about"} className={"container"}></section> */}

      <ScrollButton />

      <Footer />

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Location Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select Location</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModal} />
        </div>

        <ScrollableFeed>
          {states.map((state: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                let ccCities = City.getCitiesOfState(
                  state.countryCode,
                  state.isoCode
                );
                // console.log(ccCities[0]?.name, "CCCC");
                // return;

                setFormData({
                  ...formData,
                  state: state.name,
                  city: ccCities[0]?.name,
                });

                setCities(ccCities);
                closeModal();
              }}
            >
              {state.name}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>

      <Modal
        isOpen={modalCity}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCity}
        style={customStyles}
        contentLabel="City Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select City</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModalCity} />
        </div>

        <ScrollableFeed>
          {cities.map((city: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                setFormData({
                  ...formData,
                  city: city.name,
                });
                closeModalCity();
              }}
            >
              {city.name}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>

      <Modal
        isOpen={modalType}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalType}
        style={customStyles}
        contentLabel="City Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select Type</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModalType} />
        </div>

        <ScrollableFeed>
          {TYPES.map((item: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                setFormData({
                  ...formData,
                  type: item,
                });
                closeModalType();
              }}
            >
              {item}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>
    </div>
  );
};
