import "animate.css"; //npm install animate.css
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Navbar } from "../../components/Navbar";
import { Sidebar } from "../../components/Sidebar";
import { auth } from "../../config/fire";
import { algoChatID, GET_USER } from "../../helper/fetch";
import { pptType, UserProps } from "../../helper/interface";
import { CREATE_FRIENDSHIP } from "../../helper/push";
import { getDateFromStamp } from "../../helper/util";

export const PropertyDetails = () => {
  const location: any = useLocation();
  const history = useHistory();

  const property: pptType = location.state?.property;
  const [loading, setLoading] = useState<boolean>(false);
  const [agent, setAgent] = useState<UserProps>({ createdAt: "" });

  useEffect(() => {
    GET_USER(property.userId)
      .then((res: any) => {
        setAgent(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const propChat = async () => {
    let uid = auth.currentUser?.uid;
    if (!agent.uid) return;
    if (!uid) return;

    let chatID: any = await algoChatID(uid, agent.uid);

    CREATE_FRIENDSHIP(chatID.id, uid, agent.uid);

    history.push({
      pathname: "/chat",
      state: { user: agent },
    });
  };

  return (
    <div className="main-wrapper">
      <ToastContainer />
      <Navbar whiteBg />
      <Sidebar whiteBg />
      {/* <div className="body"></div> */}

      <div className="properties-details-page content-area-15 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-xs-12 slider">
              <div
                id="propertiesDetailsSlider"
                className="carousel properties-details-sliders slide mb-60"
              >
                <div className="heading-properties">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pull-left">
                        <h3 id="name">{property.propertyTitle}</h3>
                        <p>
                          <i className="fa fa-map-marker"></i>{" "}
                          <span id="address">
                            {property.address} {property.city}, {property.state}.
                          </span>{" "}
                        </p>
                      </div>
                      <div className="p-r">
                        <h3 id="price">${property.price}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- main slider carousel items --> */}
                <div className="carousel-inner">
                  <div
                    className="active item carousel-item"
                    data-slide-number="0"
                  >
                    <img
                      id="imageprop"
                      src={property?.images ? property?.images[0] : ""}
                      className="img-fluid"
                      alt="property-4"
                    />
                  </div>
                </div>
                {/* <!-- main slider carousel nav controls --> */}
                <ul className="carousel-indicators smail-properties list-inline nav nav-justified">
                  {/* <!--<li className="list-inline-item active">
                            <a id="carousel-selector-0" className="selected" data-slide-to="0" data-target="#propertiesDetailsSlider">
                                <img src="assets/img/property-4.jpg" className="img-fluid" alt="property-4">
                            </a>
                        </li>--> */}
                </ul>
              </div>

              {/* <!-- Tabbing box start --> */}
              <div className="tabbing tabbing-box mb-60">
                <ul className="nav nav-tabs" id="carTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      id="one-tab"
                      data-toggle="tab"
                      href="#one"
                      role="tab"
                      aria-controls="one"
                      aria-selected="false"
                    >
                      Description
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="three-tab"
                      data-toggle="tab"
                      href="#three"
                      role="tab"
                      aria-controls="three"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="four-tab"
                      data-toggle="tab"
                      href="#four"
                      role="tab"
                      aria-controls="four"
                      aria-selected="true"
                    >
                      Agent
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="carTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <h3 className="heading">Property Description</h3>
                    <p id="desc">{property.information}.</p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                  >
                    <div className="floor-plans mb-60">
                      <h3 className="heading">Floor Plans</h3>

                      <img
                        src="assets/img/floor-plans.png"
                        alt="floor-plans"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="three"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                  >
                    <div className="property-details">
                      <h3 className="heading">Property Details</h3>
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <ul>
                            <li>
                              <strong>Land area:</strong>{" "}
                              <span id="area"></span>
                              {property.area_sqft} sqft
                            </li>

                            <li>
                              <strong>Bathrooms:</strong> {property.bathroom}
                              <span id="bath"></span>
                            </li>
                            <li>
                              <strong>Bedrooms:</strong> {property.bedroom}
                              <span id="bed"></span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <ul>
                            <li>
                              <strong>Property Age:</strong>
                              <span id="age"></span>
                              {property.building_age} year(s)
                            </li>
                            <li>
                              <strong>Price:</strong>
                              <span id="price2"></span> ${property.price}
                            </li>
                            <li>
                              <strong>Price:</strong>
                              <span id="type"></span> {property.type}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <ul>
                            <li>
                              <strong>City:</strong>
                              <span id="city"></span> {property.city}
                            </li>
                            <li>
                              <strong>State:</strong>
                              <span id="state"></span> {property.state}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="four"
                    role="tabpanel"
                    aria-labelledby="four-tab"
                  >
                    <div className="section location">
                      <h3 className="heading">Property Agent</h3>
                      <div className="col-md-6 col-sm-12">
                        <ul>
                          <li>
                            <strong>Name:</strong> <span id="area"></span>
                            {agent.name}
                          </li>
                          <li>
                            <strong>Member since:</strong>{" "}
                            <span id="area"></span>
                            {getDateFromStamp(agent?.createdAt)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Amenities box start --> */}
              <div className="amenities-box mb-60">
                <h3 className="heading" id="sell"></h3>
              </div>
              {/* <!-- Features opions start --> */}
              <div className="features-opions mb-60">
                <h3 className="heading">Features</h3>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <ul>
                      {property.swimmingpool && (
                        <li id="swimming">
                          <i className="flaticon-swimmer"></i>
                          Swimming Pool
                        </li>
                      )}
                      {property.balcony && (
                        <li id="balcony">
                          <i className="flaticon-balcony-and-door"></i>
                          Balcony
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <ul>
                      {property.free_parking && (
                        <li id="parking">
                          <i className="flaticon-parking"></i>
                          Free Parking
                        </li>
                      )}
                      {property.laundry_room && (
                        <li id="parking">
                          <i className="flaticon-parking"></i>
                          Laundry
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className={"d-flex"}>
                <div className={"flex-center"}>
                  <button
                    onClick={(e) => {
                      propChat();
                    }}
                    className={"btn btn-primary"}
                  >
                    {"Chat with Agent"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
