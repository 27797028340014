import React, { Component } from "react";
import logo from "../assets/img/logo.png";
export default class footer extends Component {
  render() {
    return (
      <footer className="container">
        <hr />
        <div className="d-flex row muse pt-1">
          <div className="col-lg-2 col-md-2 col-sm-12 muse">
            <img src={logo} />
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 muse-body">
            Powered by the
            <i className="fa fa-heart mx-1"></i>
            of volunteers who desire solely to do good.
          </div>
        </div>
      </footer>
    );
  }
}
