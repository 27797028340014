import React, { useState } from "react";
import "./sidebar.scope.scss";
import logo from "../assets/img/logo.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import { auth } from "../config/fire";

export const Sidebar = (props) => {
  const { whiteBg } = props;

  const history = useHistory();
  const [isOpen, setisOpen] = useState(false);
  const sidebarToggle = () => {
    setisOpen(!isOpen);
  };

  const [isLoggedIn, setisLoggedIn] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
    }
  });

  const authInit = (auth) => {
    history.push({ pathname: "/auth", state: { auth } });
  };
  return (
    <>
      <div
        className={
          isOpen ? "overlay-isOpen sidebar-overlay" : "sidebar-overlay"
        }
      >
        <div className="close" onClick={sidebarToggle}>
          <span
            className="iconify"
            data-icon="clarity:times-line"
            data-inline="false"
          ></span>
        </div>
      </div>

      <div
        className={
          isOpen
            ? "navbar-toggler menu-icon d-none"
            : "navbar-toggler menu-icon"
        }
        onClick={sidebarToggle}
      >
        <span
          class="iconify"
          data-icon="grommet-icons:menu"
          data-inline="false"
        ></span>
      </div>
      <div className="nav-logo-2">
        <NavLink to="/">
          <img className={whiteBg ? "imgWh" : ""} src={logo} alt="" />
        </NavLink>
      </div>
      <section className={isOpen ? "sidebar-isOpen sidebar" : "sidebar"}>
        <div className="sidebar-wrapper">
          <div className="side-logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="mt-5">
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/"
              activeClassName="active"
            >
              Home
            </NavLink>

            {isLoggedIn && (
              <div
                className="side-item"
                onClick={
                  (sidebarToggle,
                  () => {
                    history.push("/dashboard");
                  })
                }
                activeClassName="active"
              >
                Dashboard
              </div>
            )}

            {isLoggedIn && (
              <div
                className="side-item"
                onClick={() => {
                  history.push("/dashboard/#chats");
                }}
              >
                Chat
              </div>
            )}
            <a className="side-item" onClick={sidebarToggle} href="/#properties">
              Properties
            </a>

            <a className="side-item" onClick={sidebarToggle} href="/#about">
              About
            </a>
            {/* <a className="side-item" onClick={sidebarToggle} href="/#options">
              Options
            </a> */}

            {/* <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/faq"
              activeClassName="active"
            >
              FAQ
            </NavLink> */}

            {!isLoggedIn ? (
              <>
                <div
                  className="side-item"
                  onClick={(sidebarToggle, () => authInit("login"))}
                  activeClassName="active"
                >
                  Login
                </div>
                {/* <div
                  className="side-item"
                  onClick={(sidebarToggle, () => authInit("join"))}
                  activeClassName="active"
                >
                  Sign Up
                </div> */}
              </>
            ) : (
              <div
                className="side-item"
                onClick={
                  (sidebarToggle,
                  () => {
                    auth.signOut();
                    history.push("/");
                  })
                }
                activeClassName="active"
              >
                Logout
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
