// import firebase from "firebase/app";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDcZMj6scRCmvhkmP8osXTzxoB4kuollH8",
  authDomain: "mattew2540.firebaseapp.com",
  projectId: "mattew2540",
  storageBucket: "mattew2540.appspot.com",
  messagingSenderId: "1024505967578",
  appId: "1:1024505967578:web:7b42de380829985d0ac6e3",
  measurementId: "G-EEPVS6Z7K4",
};

const fire = firebase.initializeApp(config);
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
firebase.analytics();

export { auth, db, storage, firebase };
