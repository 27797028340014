import { Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "animate.css"; //npm install animate.css
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import ScrollableFeed from "react-scrollable-feed";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../components/footer";
import { Navbar } from "../components/Navbar";
import ScrollButton from "../components/ScrollButton";
import { Sidebar } from "../components/Sidebar";
import { auth } from "../config/fire";
import { GET_PROPERTY, GET_USER } from "../helper/fetch";
import { pptType } from "../helper/interface";
import { ADD_FAV } from "../helper/push";
import { Country, State, City } from "country-state-city";
import { ICity, IState } from "country-state-city/dist/lib/interface";
import { COUNTRIES } from "../config/constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
  },
  h2: {
    // fontSize: "80px",
  },
};

const TYPES = ["Home Rentals", "Apartment Rentals"];

export const Home = () => {
  const history = useHistory();
  const [property, setProperty] = useState<pptType[]>([]);
  const [propertyCopy, setPropertyCopy] = useState<pptType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSeaching, setSearching] = useState<boolean>(false);
  const [user, setuser] = useState<any>({
    isAgent: false,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalCity, setModalCity] = React.useState(false);
  const [modalType, setModalType] = React.useState(false);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [filterState, setFilterState] = useState<IState>({
    name: "Arizona",
    isoCode: "Az",
    countryCode: "US",
  });
  const [filterCity, setFilterCity] = useState<ICity>({
    name: "Phoenix",
    stateCode: "Ph",
    countryCode: "US",
  });
  const [filterType, setFilterType] = useState<string>(TYPES[0]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeModalType() {
    setModalType(false);
  }

  function closeModalCity() {
    setModalCity(false);
  }

  useEffect(() => {
    // let states = ;
    console.log(COUNTRIES, "NEW STATESSSS");
    setStates(COUNTRIES);
    setCities(City.getCitiesOfState("US", "AZ"));
    // setFilterState(State.getStateByCodeAndCountry("Al", "US"));

    setLoading(true);

    GET_PROPERTY()
      .then((res: any) => {
        setProperty(res);
        setPropertyCopy(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    setTimeout(() => {
      let id = auth.currentUser?.uid;

      if (id) {
        GET_USER(id)
          .then((res: any) => {
            setuser(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 2000);
  }, []);

  const filterResult = () => {
    // property.filter
    let filteredCity = property.filter((item) => item.city == filterCity.name);

    let filteredState = filteredCity.filter(
      (item) => item.state == filterState.name
    );

    let filteredType = filteredState.filter((item) => item.type == filterType);

    setProperty(filteredType);
  };

  const propNav = (property: any) => {
    history.push({
      pathname: "/properyDetails",
      state: { property },
    });
  };

  const authNav = () => {
    history.push({
      pathname: "/auth",
      state: { auth: "join" },
    });
  };

  return (
    <div className="main-wrapper">
      <ToastContainer />

      <div className="body">
        <section className="main-container">
          <Navbar />
          <Sidebar />
          <header>
            <div className="w-100">
              <div className="header-text">
                Providing accessible housing options at your fingertips.
              </div>
            </div>
          </header>
        </section>

        <div className={"d-flex justify-content-center"}>
          <div
            className={
              "col-lg-8 col-md-10 col-sm-12 search-box d-flex align-items-center row"
            }
          >
            <div
              className={"lr location click"}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <h6 className={"box-head"}>
                Location
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-down-alt2"
                  data-inline="false"
                />
              </h6>
              <p className={"box-subtitle"}>{filterState.name}</p>
            </div>
            <div
              className={"lr location click"}
              onClick={() => {
                setModalCity(true);
              }}
            >
              <h6 className={"box-head"}>
                City
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-down-alt2"
                  data-inline="false"
                />
              </h6>
              <p className={"box-subtitle"}>{filterCity.name}</p>
            </div>
            <div
              className={"lr propetyType click"}
              onClick={() => {
                setModalType(true);
              }}
            >
              <h6 className={"box-head"}>
                Property Type
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-down-alt2"
                  data-inline="false"
                />
              </h6>
              <p className={"box-subtitle"}>{filterType}</p>
            </div>
            {/* <div className={"lr averagePrice"}>
              <h6 className={"box-head"}>
                Average Price
                <span
                  className="iconify"
                  data-icon="dashicons:arrow-down-alt2"
                  data-inline="false"
                />
              </h6>
              <p className={"box-subtitle"}>$500-$1000</p>
            </div> */}
            <div
              className={"searchbtn "}
              onClick={() => {
                setSearching(true);
                filterResult();
              }}
            >
              <button className={"btn"}>Search</button>
            </div>
          </div>
        </div>

        {!isSeaching && (
          <section id={"about"} className={"container"}>
            <div className="text-center">
              <h1>What We Provide</h1>
              <p>
                Access to second chance housing options right at your
                fingertips.
              </p>
            </div>

            <div className="d-flex flex-wrap mt-5">
              <div className="col-lg-3 col-md-6 col-sm-6 info-box fadeInUp_Anim">
                <span
                  className="iconify"
                  data-icon="cil:house"
                  data-inline="false"
                />
                <h5>House Rentals</h5>
                <p>
                  Affordable, second chance housing options listed by private
                  owners and management companies.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 info-box fadeInUp_Anim delay_3">
                <span
                  className="iconify"
                  data-icon="la:hotel"
                  data-inline="false"
                />
                <h5>Apartment Rentals</h5>
                <p>Affordable, second chance apartment options.</p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 info-box fadeInUp_Anim delay_5">
                <span
                  className="iconify"
                  data-icon="bx:bx-support"
                  data-inline="false"
                />
                <h5>24/7 Access</h5>
                <p>
                  Search for affordable housing options whenever you need to
                  from wherever you are.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 info-box fadeInUp_Anim delay_7">
                <span
                  className="iconify"
                  data-icon="ic:outline-real-estate-agent"
                  data-inline="false"
                />{" "}
                <h5>Personal Profile</h5>
                <p>
                  Create a profile and communicate through chat with prospective
                  housing options. Navigate to their website through the site
                  and complete an application. Find out if you're approved right
                  on the website.
                </p>
              </div>
            </div>
          </section>
        )}

        <section id={"properties"}>
          {/* <!-- Recent Properties start --> */}
          <div className="recent-properties content-area-2">
            <div className="container">
              <div className="main-title">
                <div
                  className={
                    isSeaching
                      ? "px-4 d-flex align-items-center justify-content-between row"
                      : ""
                  }
                >
                  <h1>
                    {isSeaching ? "Search Result" : "Properties You May Like"}
                  </h1>
                  {isSeaching && (
                    <ClearIcon
                      className={"mb-2"}
                      onClick={() => {
                        setProperty(propertyCopy);
                        setSearching(false);
                      }}
                    />
                  )}
                </div>
                <p>
                  Our smart technology has found the following options for you.
                </p>
              </div>

              {loading ? (
                <div className={"container d-flex flex-center"}>
                  <div className={"flex-cen spinner"}>
                    <ReactLoading
                      className={"spinner"}
                      type={"spin"}
                      color={"#71db77"}
                      height={"100%"}
                      width={"70%"}
                    />
                  </div>
                  Fetching Available Options
                </div>
              ) : property.length < 1 ? (
                <div className={"flex_cen"}>
                  No options listed at this time. Please continue to check as
                  options are listed regularly.
                </div>
              ) : (
                <div className="row">
                  {property.map((element) => (
                    <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
                      <div className="property-box-8">
                        <div className="property-photo">
                          <img
                            id="img1"
                            src={element?.images ? element?.images[0] : ""}
                            alt="property image"
                            className="img-fluid"
                            onClick={(e) => {
                              if (user.uid) propNav(element);
                              else authNav();
                            }}
                          />
                          <div className="date-box">
                            For {element.rent ? "Rent" : "Sale"}
                          </div>

                          <div
                            className={"fav-box click"}
                            onClick={(e) => {
                              if (user.uid) {
                                if (element.id)
                                  ADD_FAV(user?.uid, element.id).then((res) => {
                                    if (res == true) {
                                      toast("Added to Favourite");
                                    } else {
                                      toast("Error Adding to Favourite");
                                    }
                                  });
                              } else authNav();
                            }}
                          >
                            <i
                              id="fav0"
                              className="fa fa-heart-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div
                          className="detail"
                          onClick={(e) => {
                            if (user.uid) propNav(element);
                            else authNav();
                          }}
                        >
                          <div className="heading">
                            <h6>
                              <a href="#" id="recname1">
                                {element.propertyTitle}
                              </a>
                            </h6>
                            <div className="location">
                              <a>
                                <i className="flaticon-facebook-placeholder-for-locate-places-on-maps"></i>
                                <h6 id="recloc1">
                                  {element.address}. {element.city}.<br />{" "}
                                  {element.state}.
                                </h6>
                              </a>
                            </div>
                          </div>
                          <div className="properties-listing">
                            <span id="recbed1">{element.bedroom} Beds</span>
                            <span id="recbath1">{element.bathroom} Baths</span>
                            <span id="recarea1">{element.area_sqft} sqft</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>

        <section id={"invite"} className={""}></section>
        <ScrollButton />

        <Footer />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Location Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select Location</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModal} />
        </div>

        <ScrollableFeed>
          {states.map((state: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                setFilterState(state);
                setFilterCity(
                  City.getCitiesOfState(state.countryCode, state.isoCode)[0]
                );
                setCities(
                  City.getCitiesOfState(state.countryCode, state.isoCode)
                );
                closeModal();
              }}
            >
              {state.name}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>

      <Modal
        isOpen={modalCity}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCity}
        style={customStyles}
        contentLabel="City Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select City</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModalCity} />
        </div>

        <ScrollableFeed>
          {cities.map((city: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                setFilterCity(city);
                closeModalCity();
              }}
            >
              {city.name}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>

      <Modal
        isOpen={modalType}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalType}
        style={customStyles}
        contentLabel="City Modal"
      >
        <div
          className={
            "d-flex align-items-center justify-content-between row px-4"
          }
        >
          <h2 className={"header2 mr-5"}>Select Type</h2>
          {/* <button onClick={closeModal}>close</button> */}
          <ClearIcon className={"mb-2"} onClick={closeModalType} />
        </div>

        <ScrollableFeed>
          {TYPES.map((item: any) => (
            <div
              className={"py-2 click"}
              onClick={() => {
                setFilterType(item);
                closeModalType();
              }}
            >
              {item}
            </div>
          ))}
        </ScrollableFeed>
      </Modal>
    </div>
  );
};
