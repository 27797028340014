import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MenuIcon from "@material-ui/icons/Menu";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ScrollableFeed from "react-scrollable-feed";
import logo from "../assets/img/logo.png";
import ImageUpload from "../components/ImageUpload";
import Message from "../components/Message";
import { auth, db } from "../config/fire";
import { algoChatID } from "../helper/fetch";
import { UserProps } from "../helper/interface";
import firebase from "firebase";
import { setuid } from "process";
import {
  dynamicSort,
  getDateFromStamp,
  getDateTimeFromStamp,
} from "../helper/util";
import moment from "moment";
const drawerWidth = 0;
let unsubscribe: any;

const useStyles = makeStyles((theme) => ({
  inputFile: {
    display: "none",
  },
  appBar: {
    background: "#fff",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  footer: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  footerContent: {
    display: "flex",
    position: "absolute",
    left: 12,
    right: 12,
    bottom: 6,
    backgroundColor: "#e1fce3",
    alignItems: "center",
    borderRadius: "5px",
  },
  form: {
    display: "flex",
    width: "100%",
    paddingLeft: 12,
  },
  chat: {
    marginTop: "6%",
    position: "relative",
    height: "calc(100vh - 150px)",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  channel_name: {
    backgroundColor: "#e1fce3",
    // margin: theme.spacing(3)
  },
  empty: {
    color: "grey",
    margin: theme.spacing(10),
  },
  avatar: {
    height: 25,
    objectFit: "contain",
  },
  toolbar: theme.mixins.toolbar,
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  logo: {
    height: 50,
    objectFit: "contain",
  },
  whText: {
    color: "#050d21",
  },
  menu: {
    "& .MuiPaper-root": {
      //   backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const params = useParams();
  const [allMessages, setAllMessages] = useState<any>([]);
  const [channelName, setChannelName] = useState("");
  const [userNewMsg, setUserNewMsg] = useState("");
  const [dialogState, setDialogState] = useState(false);
  const [file, setFileName] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location: any = useLocation();
  const history = useHistory();

  const agent: UserProps = location.state?.user;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    listenForessages();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const [chatID, setChatID] = useState("");
  const [uid, setUid] = useState("");
  const [loading, setLoading] = useState(false);

  const listenForessages = async () => {
    // Geeting messages from NODE: chat => message => chatID
    let uid = auth.currentUser?.uid;
    if (!uid || !agent.uid) {
      history.goBack();
      return;
    }
    setLoading(true);
    let chatID: any = await algoChatID(uid, agent.uid);
    setChatID(chatID.id);
    setUid(uid);

    db.collection("chat")
      .doc("messages")
      .collection(chatID.id)
      .orderBy("createdAt", "desc")
      .onSnapshot(function (querySnapshot) {
        if (querySnapshot.empty) {
          // No Message found, return
          console.log("No Messages found");
          return;
        }
        // initialize empty chat arrray and push chat items
        let chats: any[] = [];
        // let batch = db.batch();
        let hasUnread = false;
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          // console.log("called");
          var itemVal = doc.data();
          // console.log(itemVal.text);
          // let stampDate = itemVal.createdAt.toDate();
          chats.push({
            _id: itemVal._id,
            text: itemVal.text,
            image: itemVal.image,
            read: itemVal.read,
            timeAgo: moment(itemVal._id).fromNow(),
            createdAt: getDateTimeFromStamp(itemVal._id),
            user: {
              _id: itemVal.uid,
              avatar: agent?.name,
            },
          });

          // console.log(itemVal.uid, user.id, itemVal.read);
          if (!itemVal.read && itemVal.uid != uid) {
            hasUnread = true;
            let transactionRef = db
              .collection("chat")
              .doc("messages")
              .collection(chatID)
              .doc(doc.id);
            // batch.update(transactionRef, { read: true });
          }
        });

        // Add messages to app state
        chats.sort(dynamicSort("createdAt"));

        setAllMessages(chats);
        setLoading(false);

        // console.log(chats);

        // if (hasUnread)
        //   batch
        //     .commit()
        //     .then(function () {
        //       console.log("Read Transaction Complete");
        //     })
        //     .catch(function (error) {
        //       console.log("Error creating transaction => " + error);
        //     });
      });
  };

  const onSend = (e: any) => {
    e.preventDefault();
    // saving new message to firestore

    var now = new Date().getTime();
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    let snap: any = {
      _id: now,
      createdAt: serverTimestamp,
      read: false,
      uid: uid,
      text: userNewMsg,
      order: -1 * now,
    };

    // if (message.text) {
    //   snap.text = message.text;
    // } else {
    //   snap.image = message.image;
    // }

    db.collection("chat").doc("messages").collection(chatID).add(snap);

    setUserNewMsg("");
    if (agent?.push_token && agent.push_token != "na") {
      let snap = {
        chatID,
        agentId: agent.uid,
        agentName: agent.name,
      };

      // let data = { ...snap, route: "ChatScreen" };

      // return;
      // sendPushNotification(
      //   peerProfile.push_token,
      //   user.names,
      //   message.text ? message.text : "Image",
      //   data
      // );
    }
  };

  const handleUploadDialog = () => {
    setDialogState(!dialogState);
  };

  const handelFileUpload = (e: any) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setFileName(e.target.files[0]);
      handleUploadDialog();
    }
    e.target.value = null;
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton> */}
          <div className={classes.header}>
            <img
              src={logo}
              onClick={() => {
                history.goBack();
              }}
              alt="logo"
              className={classes.logo}
            />
            <Typography className={classes.whText}>{agent.name}</Typography>

            <IconButton onClick={handleClick}>
              <Avatar alt={agent.name} src={agent.email} />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menu}
            >
              {/* <MenuItem onClick={toggleEditProfile}>Edit Profile</MenuItem> */}
              {/* <MenuItem onClick={signOut}>Logout</MenuItem> */}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {dialogState && <ImageUpload setState={handleUploadDialog} file={file} />}
      <Grid item xs={12} className={classes.channel_name}>
        <Typography className={classes.whText}>{agent.name}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.chat}>
        <ScrollableFeed>
          {allMessages.length > 0 ? (
            allMessages.map((message: any) => (
              <Message
                key={message._id}
                values={message}
                msgId={message.user._id}
                userID={uid}
              />
            ))
          ) : (
            <Typography className={classes.empty}>
              Profile connected.
              <br />
              You can Start chatting :)
            </Typography>
          )}
        </ScrollableFeed>
      </Grid>
      <div className={classes.footer}>
        <Grid item xs={12} className={classes.footerContent}>
          {/* <input
            accept="image/*"
            id="input-file"
            className={classes.inputFile}
            type="file"
            onChange={(e) => handelFileUpload(e)}
          />
          <label htmlFor="input-file">
            <IconButton aria-label="upload picture" component="span">
              <AttachFileIcon />
            </IconButton>
          </label> */}
          <form
            autoComplete="off"
            onSubmit={(e) => {
              onSend(e);
            }}
            className={classes.form}
          >
            <TextField
              required
              autoFocus
              fullWidth
              multiline
              rows={1}
              rowsMax={1}
              value={userNewMsg}
              onChange={(e) => {
                setUserNewMsg(e.target.value);
              }}
              margin="normal"
              placeholder="Enter Message"
            />
            <IconButton type="submit" component="button">
              <SendIcon />
            </IconButton>
          </form>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;
