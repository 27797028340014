import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { AddProperty } from "./pages/AddProperty/index";
import { Auth } from "./pages/Auth";
import Chat from "./pages/Chat";
import { Dashboard } from "./pages/Dashboard/index";
import { Home } from "./pages/Home";
import { PropertyDetails } from "./pages/PropertyDetails";

function App() {
  return (
    <BrowserRouter>
      <div className="body">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/addproperty" component={AddProperty} />
          <Route exact path="/properyDetails" component={PropertyDetails} />
          <Route exact path="/chat" component={Chat} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
