import "animate.css"; //npm install animate.css
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "../../components/footer";
import { Navbar } from "../../components/Navbar";
import ScrollButton from "../../components/ScrollButton";
import { Sidebar } from "../../components/Sidebar";
import { auth } from "../../config/fire";
import {
  GET_FAV_PROPERTY,
  GET_FRIENDS,
  GET_PROPERTY_AGENT,
  GET_USER,
} from "../../helper/fetch";
import ScrollableFeed from "react-scrollable-feed";

import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { pptType } from "../../helper/interface";
import "./dashboard.scss";
import Message from "../../components/Message";
import ChatRow from "../../components/ChatRow";

export const Dashboard = () => {
  const history = useHistory();
  const [user, setuser] = useState<any>({
    isAgent: false,
  });
  const [property, setProperty] = useState<pptType[]>([]);
  const [activeChats, setActiveChats] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      let id = auth.currentUser?.uid;

      if (id) {
        setLoading(true);
        GET_USER(id)
          .then((res: any) => {
            setuser(res);
            if (res.isAgent == true) {
              GET_PROPERTY_AGENT(id)
                .then((res: any) => {
                  setLoading(false);
                  setProperty(res);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            } else {
              GET_FAV_PROPERTY(id)
                .then((res: any) => {
                  setLoading(false);
                  setProperty(res);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });

        GET_FRIENDS(id)
          .then((res: any) => {
            // console.log(res.user, "FRIEND");
            setActiveChats(res);
          })
          .catch((err) => {
            console.log(err, "GET_FREIEND ERR");
          });
      }
    }, 2000);
  }, [auth]);

  const propNav = (property: any) => {
    history.push({
      pathname: "/properyDetails",
      state: { property },
    });
  };

  return (
    <div id={"dashboard"} className="dash-wrapper">
      <ToastContainer />

      <section className="dash-header">
        <Navbar />
        <Sidebar />
        <header>
          <div className="w-100">
            <div className="header-text">Listings</div>
          </div>

          {user?.isAgent && (
            <div className={"flex-center"}>
              <button
                onClick={(e) => {
                  history.push("/addproperty");
                }}
                className={"btn btn-primary"}
              >
                {"Add Property"}
              </button>
            </div>
          )}
        </header>
      </section>

      <div className={"d-flex justify-content-center"}>
        <div
          className={
            "col-lg-12 col-md-112 col-sm-12 search-box d-flex align-items-center row"
          }
        ></div>
      </div>

      <section id={"property-listing"} className={"container"}>
        <div className="d-flex">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="main-title">
                <h1>
                  {user?.isAgent ? "My Properties List" : "Favorite List"}
                </h1>
              </div>

              {/* <!-- Container --> */}
              <div id="contain">
                <div className="property-box-5" id="item">
                  {loading ? (
                    <div className={"container d-flex flex-center"}>
                      <div className={"flex-cen spinner"}>
                        <ReactLoading
                          className={"spinner"}
                          type={"spin"}
                          color={"#71db77"}
                          height={"100%"}
                          width={"70%"}
                        />
                      </div>
                      Fetching favourite properties
                    </div>
                  ) : property.length < 1 ? (
                    <div className={"flex_cen"}>
                      {user?.isAgent
                        ? "You haven't posted any property yet."
                        : "You haven't favourited any properties."}
                    </div>
                  ) : (
                    property.map((element) => (
                      <>
                        <div className="row mt-2">
                          <div className="col-lg-5 col-md-5 col-pad">
                            <div className="property-thumbnail" id="imagebox">
                              <a className="property-img">
                                <div className="price-ratings-box">
                                  <p className="price" id="price">
                                    ${element.price}
                                  </p>
                                </div>
                                <img
                                  id="imageref"
                                  // src={pp1}
                                  src={
                                    element?.images ? element?.images[0] : ""
                                  }
                                  alt="Property Image"
                                  className="img-fluid click"
                                  onClick={(e) => {
                                    propNav(element);
                                  }}
                                />
                              </a>
                              <div className="property-overlay">
                                {/* <!-- */}
                                <a className="overlay-link">
                                  <i className="fa fa-link"></i>
                                </a>
                                <a
                                  className="overlay-link property-video"
                                  title=""
                                >
                                  <i className="fa fa-video-camera"></i>
                                </a>
                                <div className="property-magnify-gallery">
                                  <a
                                    href="assets/img/property-1.jpg"
                                    className="overlay-link"
                                  >
                                    <i className="fa fa-expand"></i>
                                  </a>
                                </div>
                                {/* --> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 align-self-center col-pad">
                            <div
                              className="detail click"
                              onClick={(e) => {
                                propNav(element);
                              }}
                            >
                              {/* <!-- title --> */}
                              <h1 className="title">
                                <a id="name">{element?.propertyTitle}</a>
                              </h1>
                              {/* <!-- Location --> */}
                              <div className="location">
                                <a>
                                  <i
                                    className="fa fa-map-marker"
                                    id="address"
                                  ></i>
                                  {element.address}. {element.city}.
                                </a>
                              </div>
                              {/* <!-- Paragraph --> */}
                              <p id="detail">{element.information}</p>
                              {/* <!--  Facilities list --> */}
                              <ul className="facilities-list clearfix">
                                <li>
                                  <i className="flaticon-bed"></i>{" "}
                                  <span id="bed">{element.bedroom}</span> Beds
                                </li>
                                <li>
                                  <i className="flaticon-bath"></i>{" "}
                                  <span id="bath">{element.bathroom}</span>{" "}
                                  Baths
                                </li>
                                <li>
                                  <i className="flaticon-square-layouting-with-black-square-in-east-area"></i>{" "}
                                  Sq Ft{" "}
                                  <span id="area">{element.area_sqft}</span>
                                </li>
                                <li>
                                  <i className="flaticon-car-repair"></i>{" "}
                                  <span id="sell"></span>
                                </li>
                                {/* <li
                                  className="favourite click"
                                  onClick={(e) => {
                                    console.log(user);
                                    if (user.uid) {
                                      if (element.id)
                                        ADD_FAV(user?.uid, element.id).then(
                                          (res) => {
                                            if (res == true) {
                                              toast("Added to Favourite");
                                            } else {
                                              toast(
                                                "Error Adding to Favourite"
                                              );
                                            }
                                          }
                                        );
                                    } else {
                                      history.push({
                                        pathname: "/auth",
                                        state: { auth: "join" },
                                      });
                                    }
                                  }}
                                >
                                  <i
                                    id="fav0"
                                    // onClick="favorite(this)"
                                    className="fa fa-heart-o"
                                    aria-hidden="true"
                                  ></i>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div id="chats" className="col-lg-4 col-md-12">
              <div className="main-title">
                <h1>{"Active Chats"}</h1>

                <Grid item xs={12} className={classes.chat}>
                  <ScrollableFeed>
                    {activeChats.length > 0 ? (
                      activeChats.map((chat: any) => (
                        <div
                          onClick={() => {
                            history.push({
                              pathname: "/chat",
                              state: { user: chat.user },
                            });
                          }}
                        >
                          <ChatRow
                            key={chat.id?.trim()}
                            names={chat.user.name}
                            chatId={chat.id}
                            lastMsg={chat.lastChat?.lastChat}
                          />
                        </div>
                      ))
                    ) : (
                      <Typography className={classes.empty}>
                        No active chats found
                      </Typography>
                    )}
                  </ScrollableFeed>
                </Grid>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <div className="mbl">
                <div className="widget search-area">
                  <h5 className="sidebar-title">Advanced Search</h5>
                  <div className="search-area-inner">
                    <div className="search-contents ">
                      <form method="GET">
                        <div className="form-group">
                          <label>Area From</label>
                          <select
                            className="selectpicker search-fields"
                            name="area"
                            id="select_area"
                          >
                            <option>Area From</option>
                            <option>1500</option>
                            <option>1200</option>
                            <option>900</option>
                            <option>600</option>
                            <option>300</option>
                            <option>100</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Property Status</label>
                          <select
                            className="selectpicker search-fields"
                            name="Status"
                            id="select_status"
                          >
                            <option>Property Status</option>
                            <option>For Sale</option>
                            <option>For Rent</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Location</label>
                          <select
                            className="selectpicker search-fields"
                            name="Location"
                            id="select_location"
                          >
                            <option>Location</option>
                            <option>Ahmedabad</option>
                            <option>Gandhinagar</option>
                            <option>Vadodara</option>
                            <option>Surat</option>
                            <option>Rajkot</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Bedrooms</label>
                          <select
                            className="selectpicker search-fields"
                            name="bedrooms"
                            id="select_bedrooms"
                          >
                            <option>Bedrooms</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Bathrooms</label>
                          <select
                            className="selectpicker search-fields"
                            name="bedrooms"
                            id="select_bathrooms"
                          >
                            <option>Bathrooms</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                        <br></br>
                        <div className="form-group">
                          <label>Price</label>
                          <div className="range-slider">
                            <div
                              data-min="0"
                              data-max="150000"
                              data-unit="USD"
                              data-min-name="min_price"
                              data-max-name="max_price"
                              className="range-slider-ui ui-slider"
                              aria-disabled="false"
                              id="select_price"
                            ></div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <button className="search-button btn-md btn-color">
                          Search
                        </button>
                        // onclick='search()'
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <ScrollButton />

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chat: {
    marginTop: "6%",
    position: "relative",
    height: "calc(100vh - 150px)",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  avatar: {
    height: 25,
    objectFit: "contain",
  },
  toolbar: theme.mixins.toolbar,
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  empty: {
    color: "grey",
    margin: theme.spacing(10),
  },
  logo: {
    height: 50,
    objectFit: "contain",
  },
  whText: {
    color: "#050d21",
  },
  menu: {
    "& .MuiPaper-root": {
      //   backgroundColor: theme.palette.primary.main,
    },
  },
}));
