import { IDS } from "../config/constants";
import { db, auth } from "../config/fire";

export const UPDATE_USER = (uid: string | undefined, snap: Object) => {
  let id = auth.currentUser?.uid;

  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(uid || id)
      .set(snap, { merge: true })
      .then((snapshot) => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ADD_FAV = (userId: string, postID: string) => {
  return new Promise((resolve, reject) => {
    db.collection(IDS.USER_ACTIONS)
      .doc(IDS.FAVOURITES)
      .collection(userId)
      .doc(postID)
      .set({ [postID]: true, createdAt: +new Date() }, { merge: true })
      .then(function (snapshot) {
        console.log("Added to Favurite");
        resolve(true);
      })
      .catch(function (error) {
        console.log("Error adding Favourite", error);
        resolve(false);
      });
  });
};

export const CREATE_FRIENDSHIP = (id: string, userA: string, userB: string) => {
  db.collection("friends")
    .doc(id)
    .set({ userA, userB, createdAt: +new Date() })
    .then((snapshot) => {
      console.log("Relationship created successfully");
    })
    .catch((error) => {
      alert("Error creating relationship. " + error);
    });
};
