import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import "./auth.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { auth } from "../../config/fire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { UPDATE_USER } from "../../helper/push";

export const Auth = () => {
  const location: any = useLocation();
  const history = useHistory();

  const authData = location.state?.auth;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    isAgent: false,
  });

  const [loading, setloading] = useState(false);

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { name, email, password, isAgent } = formData;

  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    if (authData === "join") {
      setShowRegister(true);
    } else {
      setShowRegister(false);
    }
  }, []);

  const authInit = (e: any) => {
    e.preventDefault();
    if (showRegister) signUp();
    else login();
  };

  const login = () => {
    if (!email) {
      toast("Email Address is required");
      return;
    }

    if (!password) {
      toast("Password is required");
      return;
    }

    setloading(true);

    auth
      .signInWithEmailAndPassword(email, password)
      .then((user: any) => {
        UPDATE_USER(user.user?.uid, { last_logged_in: Date.now() });
        setloading(false);
        history.push("/dashboard");
      })
      .catch((error: any) => {
        setloading(false);
        toast("" + error.message);
        console.log("Login Error: " + error);
      });
  };

  const signUp = () => {
    if (!name) {
      toast("Name is required");
      return;
    }

    if (!email) {
      toast("Email Address is required");
      return;
    }

    if (!password) {
      toast("Password is required");
      return;
    }

    setloading(true);

    auth.createUserWithEmailAndPassword(email, password).then(
      function (user: any) {
        if (!user) {
          toast("Error creating account, Please again");
          return;
        }
        const { user: innerUser } = user;

        logAccount(innerUser);
      },
      function (error: any) {
        // Handle Errors here.
        var errorMessage = error.message;
        toast(errorMessage);
        console.log(errorMessage);
      }
    );
  };

  const logAccount = (user: any) => {
    UPDATE_USER(user?.uid, {
      uid: user.uid,
      name,
      email,
      isAgent,
      last_logged_in: Date.now(),
      createdAt: Date.now(),
    })
      .then((res) => {
        setloading(false);
        history.push("/dashboard");
      })
      .catch((error) => {
        toast("Error saving details." + error);
        setloading(false);
        console.log(error);
      });
  };

  return (
    <div className="login-wrapper">
      <Sidebar />
      <ToastContainer />

      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5  col-md-12">
              <div className="login-content text-left">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="2540" />
                  </Link>
                </div>
                <div className="login-header">
                  <h3>Affordable housing at your fingertips.</h3>
                  {showRegister === false ? (
                    <p>Welcome back, login to get started</p>
                  ) : (
                    <p>Register to get started</p>
                  )}
                </div>

                <form className="login-form">
                  <div className="form-group">
                    {showRegister === true ? (
                      <div>
                        <input
                          type="text"
                          className="form-control mb-2"
                          name="name"
                          id="FullName"
                          placeholder="Name"
                          autoFocus
                          value={name}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    ) : null}
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="Email"
                      placeholder="Email Address"
                      autoFocus
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                    <input
                      type="password"
                      className="form-control mt-2"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {showRegister ? (
                      <div>
                        <label className="check-container">
                          <input
                            type="checkbox"
                            name={"isAgent"}
                            className={"check"}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.checked,
                              });
                            }}
                          />
                          <span className="checkmark"></span>
                          <span className="font14 remember ml-1">
                            Are you an agent?
                          </span>
                        </label>
                      </div>
                    ) : null}

                    {showRegister === false ? (
                      <div
                        className="font14 text-grey click"
                        data-toggle="modal"
                        data-target="#forgotPasswordModal"
                      >
                        Forgot Password?
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={showRegister === true ? "mt-3 mb-4" : "my-5 "}
                  >
                    {showRegister === false ? (
                      <button
                        className="btn btn-primary mr-3"
                        onClick={authInit}
                      >
                        {loading ? (
                          <div className={"flex-center spinner"}>
                            <ReactLoading
                              className={"spinner"}
                              type={"spin"}
                              color={"#fff"}
                              height={"100%"}
                              width={"70%"}
                            />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    ) : (
                      <>
                        <div className="my-3 policy-agreement font-AftaSans font12">
                          By continuing, you're confirming that you've read and
                          agree to our {""}
                          <Link className="text-primary" to="#">
                            terms & conditions
                          </Link>
                          {" and "}
                          <Link className="text-primary" to="#">
                            privacy policy.
                          </Link>
                        </div>
                        <button
                          className="btn btn-outline-red login-btn "
                          onClick={authInit}
                        >
                          {loading ? (
                            <div className={"flex-center spinner"}>
                              <ReactLoading
                                className={"spinner"}
                                type={"spin"}
                                color={"#71db77"}
                                height={"100%"}
                                width={"70%"}
                              />
                            </div>
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </form>
                <div className="footer-content">
                  {showRegister === false ? (
                    <p>
                      <span className="text-grey mr-2">
                        Don't have an account?
                      </span>
                      <span
                        className="btn-no-bg click"
                        onClick={() => {
                          setShowRegister(true);
                        }}
                      >
                        Sign up
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span className="text-grey mr-2">
                        Already have an Account?
                      </span>
                      <span
                        className="text-primary click"
                        onClick={() => {
                          setShowRegister(false);
                        }}
                      >
                        Login
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                showRegister === false
                  ? "col-lg-7  col-md-12 right-section login-bg d-none d-lg-block"
                  : "col-lg-7  col-md-12 right-section register-bg d-none d-lg-block"
              }
            >
              {/* <SmallFooter className='mt-auto'/> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="forgotPasswordModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="forgotPasswordModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-bold"
                id="forgotPasswordModalLabel"
              >
                Forgot Password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mt-3 mb-5">
                <div className="text-center text-grey mb-4">
                  Please enter the email address associated with your account.
                </div>
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="Email"
                      id="Email"
                      placeholder="Email Address"
                      autoFocus
                    />
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-red mr-3">Submit</button>
                <button className="btn btn-red" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
