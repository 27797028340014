import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { auth } from "../config/fire";

export const Navbar = (props) => {
  const { whiteBg } = props;
  const [isLoggedIn, setisLoggedIn] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
    }
  });
  const history = useHistory();
  window.onscroll = () => scrollFunction();

  const scrollFunction = () => {
    if (window.scrollY > 150) {
      document.querySelector(".navbar")?.classList.add("fixed-top");
    } else {
      document.querySelector(".navbar")?.classList.remove("fixed-top");
    }
  };

  const authInit = (auth) => {
    history.push({ pathname: "/auth", state: { auth } });
  };

  return (
    <div className={whiteBg ? "navbar navbarWh" : "navbar"}>
      <div className="px-4 d-flex justify-content-between align-items-center w-100">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <NavLink className="navlink" to="/" activeClassName="active">
            Home
          </NavLink>

          {isLoggedIn && (
            <>
              <div
                className="navlink click"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Dashboard
              </div>
              <div
                className="navlink click"
                onClick={() => {
                  history.push("/dashboard/#chats");
                }}
              >
                Chat
              </div>
            </>
          )}

          <a className="navlink" href="/#properties">
            Properties
          </a>
          {/* <a className="navlink" href="/#services">
            Agent
          </a> */}
          {/* <a className="navlink" href="/#contact">
            Contact
          </a> */}

          {/* <NavLink className="navlink" to="/faq" activeClassName="active">
            FAQ
          </NavLink> */}

          {!isLoggedIn ? (
            <div className="ml-4">
              <a href="#" className="navlink signup">
                <button className="btn signup" onClick={() => authInit("join")}>
                  {" "}
                  Join
                </button>
              </a>
              <a
                href="#"
                className="navlink login"
                onClick={() => authInit("login")}
              >
                Login
              </a>
            </div>
          ) : (
            <div className="ml-4">
              <a href="#" className="navlink signup">
                <button
                  className="btn signup"
                  onClick={() => {
                    auth.signOut();
                    history.push("/");
                  }}
                >
                  {" "}
                  Logout
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
